import { createSlice } from "@reduxjs/toolkit";

export const recommendedJobSlice = createSlice({
  name: "recommendedJob",
  initialState: {
    
    allJobs: {
      jobs:[],
      count: 0,
      offset: 0,
      limit: 10,
    },
    appliedJob: [],
    jobsList: {
      shortListedJobs: [],
      recommendedJobs: [],
    },
    isLoading: false,
  },
  reducers: {
    setAllJobs: (state, action) => {
      state.allJobs = action.payload;
    },
    setAppliedJobs: (state, action) => {
      state.appliedJob = action.payload;
    },
    setShortListedJobs: (state, action) => {
      state.jobsList.shortListedJobs = action.payload;
    },
    setRecommendedJobs: (state, action) => {
      state.jobsList.recommendedJobs = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const {
  setAllJobs,
  setAppliedJobs,
  setShortListedJobs,
  setRecommendedJobs,
  setIsLoading,
} = recommendedJobSlice.actions;

export default recommendedJobSlice.reducer;
