import React from "react";
import { useLocation } from "react-router-dom";
import LandingHeader from "../components/head/LandingHeader";
import { nav } from "./navigation";
import Navbar from "../components/head/Navbar";

const RenderHeader = () => {
  // Get the current location using react-router-dom's useLocation hook
  const location = useLocation();

  // Find the route object that matches the current location's pathname
  const currentRoute = nav.find((route) => route.path === location.pathname);

  // Check if the current route has isMenu set to true
  const shouldRenderHeader = currentRoute && currentRoute.isMenu;

  return (
    <>
      {/* {shouldRenderHeader ? <LandingHeader /> : <></>} */}
      {shouldRenderHeader ? <Navbar /> : <></>}
    </>
  );
};

export default RenderHeader;
