import { createSlice } from "@reduxjs/toolkit";
import { getLeastCreditDetails, getTotalCreditDetails } from "../../api/Employer/employer";

const initialState = {
  creditDetails: {
    id: "",
    type: "",
    incognito: "",
    total_credits: 0,
    available_credits: 0,
    utilized_credits: 0,
    created_at: "",
    expires_at: "",
    show_no_plan_modal: false
  },

  total_credits:[]
};

const poolCreditsSlice = createSlice({
  name: "poolViewCredits",
  initialState,
  reducers: {
    setLeastCreditDetails: (state, actions) => {
      state.creditDetails = actions.payload
    },
    setTotalCreditDetails: (state, actions) => {
      state.total_credits = actions.payload
    }
  },
});

export const { setLeastCreditDetails, setTotalCreditDetails } = poolCreditsSlice.actions;

export const fetchLeastCreditDetails = (token) => async (dispatch) => {
  try {
    const resData = await getLeastCreditDetails(token);
    if (resData?.msg == "success") {
      dispatch(setLeastCreditDetails(resData?.data));
      return resData?.data;
    }
  } catch (error) {
    console.log("Error Fetching Pool Views");
    return error;
  }
};

export const fetchTotalCreditDetails = (token) => async (dispatch) => {
  try {
    const resData = await getTotalCreditDetails(token);
    if (resData?.msg == "success") {
      dispatch(setTotalCreditDetails(resData?.data));
      return resData?.data;
    }
  } catch (error) {
    console.log("Error Fetching Pool Views");
    return error;
  }
};

export default poolCreditsSlice.reducer;