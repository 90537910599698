import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  appliedJobs: [], // Initialize as an empty array to store applied job IDs
};

const appliedJobsSlice = createSlice({
  name: 'appliedJobs',
  initialState,
  reducers: {
    setAppliedJobs: (state, action) => {
        state.appliedJobs = [...state.appliedJobs, action.payload]; // Update the appliedJobs array by concatenating the existing IDs with the new ID
      },
      
    clearAppliedJobs: (state) => {
      state.appliedJobs = []; // Clear all applied jobs by resetting to an empty array
    },
  },
});

export const { setAppliedJobs, clearAppliedJobs } = appliedJobsSlice.actions;
export const selectAppliedJobs = (state) => state.appliedJobs.appliedJobs;
export default appliedJobsSlice.reducer;