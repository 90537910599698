// userSelectionSlice.js

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  Type: "",
  domain: "",
  firstStep: "userTypeSelection",
  qualification: {},
  fieldOfStudy: {},
  job: {},
  radioButton: "qualification",
  yearsofExperience: "",
};

const userSelectionSlice = createSlice({
  name: "userPrefrences",
  initialState,
  reducers: {
    setType: (state, action) => {
      console.log("user type change :",action.payload)
      state.Type = action.payload;

    },
    setDomain: (state, action) => {
      state.domain = action.payload;
    },
    setFirstStep: (state, action) => {
      state.firstStep = action.payload;
    },
    setQualification: (state, action) => {
      state.qualification = action.payload;
    },
    setFieldOfStudy: (state, action) => {
      state.fieldOfStudy = action.payload;
    },
    setJob: (state, action) => {
      console.log("set job:", action.payload)
      state.job = action.payload;
    },
    setRadioButton: (state, action) => {
      state.radioButton = action.payload;
    },
    setyearsofExperience: (state, action) => {
      state.yearsofExperience = action.payload;
    },
    clearUserPrefrences: (state) => {
      state.Type = "";
      state.domain = "";
      state.firstStep = "userTypeSelection";
      state.qualification = {};
      state.fieldOfStudy = {};
      state.job = {};
      state.radioButton = "qualification";
      state.yearsofExperience = "";
    },
  },
});

export const {
  setType,
  setDomain,
  setFirstStep,
  setQualification,
  setFieldOfStudy,
  setJob,
  setRadioButton,
  setyearsofExperience,
  clearuserPrefrences,
} = userSelectionSlice.actions;

export const userPrefrences = (state) => state.userPrefrences;
export default userSelectionSlice.reducer;
