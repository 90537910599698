// currentPageSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentPage: 'profile', // Initial page (you can set it to any default page)
};

const currentPageSlice = createSlice({
  name: 'currentPage',
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.currentPage = action.payload; // Set the current page to the payload value
    },
  },
});

export const { setPage } = currentPageSlice.actions;
export const selectCurrentPage = (state) => state.currentPage.currentPage;
export default currentPageSlice.reducer;
