// authTypeSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  authType: "applicant",
};

const authTypeSlice = createSlice({
  name: "authType", 
  initialState,
  reducers: {
    // Action to set the authentication type in the state
    setAuthType: (state, action) => {
      state.authType = action.payload;
      console.log('Selected Auth Type:', action.payload); 
    },
   
  },
});

export const { setAuthType } = authTypeSlice.actions;
export default authTypeSlice.reducer;
