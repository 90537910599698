// questionsSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  questions: [], // Initialize as an empty array to store questions
};

const questionsSlice = createSlice({
  name: 'questions',
  initialState,
  reducers: {
    setQuestions: (state, action) => {
      state.questions = action.payload; // Set the entire array of questions
    },
    clearQuestions: (state) => {
      state.questions = []; // Clear all questions by resetting to an empty array
    },
  },
});

export const { setQuestions, clearQuestions } = questionsSlice.actions;
export const selectQuestions = (state) => state.questions.questions;
export default questionsSlice.reducer;
