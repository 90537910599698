import axios from "axios";

export const API_BASE_URL = import.meta.env.VITE_API_BASE_URL;
export const API_ML_BASE_URL = import.meta.env.VITE_API_ML_BASE_URL;
// console.log("API_BASE_URL:", API_BASE_URL);


export const api = axios.create({
  baseURL: API_BASE_URL,
});

// Function for user registration (signup)
export const signUp = async (userData) => {
  console.log("sign up req : ", userData);
  return api
    .post("/users/external/v1/register", userData, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data || error;
    });
};

export const newsignUp = async (userData) => {
  console.log("sign up req : ", userData);
  return api
    .post("users/external/v2/register", userData, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data || error;
    });
};

// function for email verification
export const verifyEmail = async (token,change_email) => {
  try {
    const response = await api.get(`users/external/v1/email-verify?token=${token}&change_email=${change_email}`)
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Function for user login
export const Login = async (email, password) => {
  try {
    const response = await api.post("/users/external/v1/login", {
      email,
      password,
      login_platform: "web",
    });

    if (response.status >= 200 && response.status < 300) {
      const authToken = response.data.data[0];
      localStorage.setItem("authToken", authToken);

      return response.data;
    } else {
      throw new Error(`Login failed with status ${response.status}`);
    }
  } catch (error) {
    throw error;
  }
};

// Function for password reset request
export const forgetPassword = async (email) => {
  const encodedEmail = encodeURIComponent(email); // Encode the emai
  const headers = {
    "Content-Type": "application/json",
  };

  return api
    .get(`/users/external/v1/forgot-password?email=${encodedEmail}`, null, {
      headers,
    })
    .then((response) => response.data)
    .catch((error) => {
      return error.response.data || error;
    });
};

// Function to verify OTP
export const OtpVerify = async (email, otpValue) => {
  const headers = {
    "Content-Type": "application/json",
  };

  const data = {
    email: email,
    otp: otpValue,
  };

  try {
    const response = await api.post("/users/external/v1/verify-otp", data, {
      headers,
    });
    if (response && response.data) {
      return response.data;
    } else {
      throw new Error("Invalid OTP verification response");
    }
  } catch (error) {
    console.error("Error during OTP verification:", error);
    throw error;
  }
};

// Function for resetting the password
export const resetPassword = async (email, password) => {
  const headers = {
    "Content-Type": "application/json",
  };

  const data = {
    email: email,
    password: password,
  };

  return api
    .post("/users/external/v1/reset-password", data, { headers })
    .then((response) => {
      console.log("Response Data:", response.data);
      return response.data;
    })
    .catch((error) => {
      console.error("Error:", error.response ? error.response.data : error);
      throw error;
    });
};

// Function for resending email
export const ResendEmail = async (emailParam) => {
  if (!emailParam) {
    console.error("Email parameter is missing.");
    return Promise.reject("Email parameter is missing.");
  }

  const requestBody = {
    type: "applicant",
  };

  return api
    .get(`/users/external/v1/resend-email?email=${emailParam}`, requestBody)
    .then((response) => response.data)
    .catch((error) => {
      return error.response.data || error;
    });
};

// Function for Google login
export const loginWithGoogle = async (access_token) => {
  return api
    .get(`/users/external/v1/google?accesstoken=${access_token}&current_platform=web`)
    .then((response) => response.data)
    .catch((error) => {
      return error.response.data || error;
    });
};

export const SignupWithGoogle = async (userData) => {
  return api
    .post("users/external/v2/google", userData, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data || error;
    });
};

// Function for LinkedIn login
export const loginWithLinkedIn = async (access_token) => {
  return api
    .get(`/users/external/v1/linkedin?accesstoken=${access_token}`)
    .then((response) => response.data)
    .catch((error) => {
      return error.response.data || error;
    });
};

// Function for Facebook login
export const loginWithFacebook = async (accessToken) => {
  return api
    .get(`/users/external/v1/facebook?accesstoken=${accessToken}`)
    .then((response) => response.data)
    .catch((error) => {
      return error.response.data || error;
    });
};

export const basicProfile = async (token) => {
  // console.log("Attempting to fetch basic profile data...");

  try {
    const res = await api.get("/applicant/external/v1/basic-profile", {
      headers: { Authorization: `Bearer ${token}` },
    });
    // console.log("API response:", res);
    return res; // Ensure that you are returning the response.
  } catch (error) {
    console.error("Error fetching basic profile data:", error);
    throw error; // Rethrow the error to be handled in the caller.
  }
};

export const deleteEmployer = async (token) => {
  try {
    const response = await api.delete(
      `/employer/external/v1/delete`,{
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching company data:", error);
    throw error;
  }
};

//function to get profile data on dashboard page

export const getprofilePercentage = async (token) => {
  try {
    const response = await api.get(
      "/applicant/external/v1/profile-percentage",
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};


export const lastLogin = async(token) =>{
  try{
   const response = await api.get('/users/external/v1/last-login?platform=web',  
   {
    headers: { Authorization: `Bearer ${token}` },
  }); 
  return response.data;
  }catch(error) {
    throw error;
  }
}


// Function for employer  registration 
export const register = async (userData) => {
  console.log("sign up req : ", userData);
  return api
    .post("/employer/external/v1/register", userData, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data || error;
    });
};

export const contactForm = async (userData) => {

  return api
    .post("/users/external/v1/enquiry-form", userData, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data || error;
    });
};



// function for employer LOgin 

export const EmployerLogin = async (email, password) => {
  try {
    const response = await api.post("/users/external/v1/login", {
      email,
      password,
      login_platform: "web",
    });

    if (response.status >= 200 && response.status < 300) {
      const authToken = response.data.data[0];
      localStorage.setItem("authToken", authToken);

      return response.data;
    } else {
      throw new Error(`Login failed with status ${response.status}`);
    }
  } catch (error) {
    throw error;
  }
};

export const getBlogs = async (offsetValue, limitValue = 8) => {
  try {
    const response = await api.get(
      `/blogs/external/v1/blog?offset=${offsetValue}&limit=${limitValue}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};




export default api;