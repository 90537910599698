// authTypeSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    industry: {id: "", title: ""},
};

const industrySlice = createSlice({
  name: "postjobIndustry", 
  initialState,
  reducers: {
    // Action to set the authentication type in the state
    setIndustry: (state, action) => {
      state.industry = action.payload;
      console.log('Selected Industry:', action.payload); 
    },
   
  },
});

export const { setIndustry } = industrySlice.actions;
export default industrySlice.reducer;
