import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sessionTime: null,
};

const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    SetSessionTime: (state, action) => {
      state.sessionTime = action.payload;
    },
    clearSessionTime: (state) => {
      state.sessionTime = null
    },
  },
});

export const {  SetSessionTime, clearSessionTime } = sessionSlice.actions;



export default sessionSlice.reducer;
