// CompanyDataSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  company_id: "",
  name: "",
  industry: "",
  size: "",
  url: "",
  hqLocation: {id:"", city:"", state:"", country:""},
  description: "",
  logo: "",
  cover_image_url: "",
  CompanyName: "",
};

const companyDataSlice = createSlice({
  name: "companyDataSlice",
  initialState,

  reducers: {
    setCompanyData: (state, action) => {
      state.company_id = action.payload.company_id;
      state.name = action.payload.name;
      state.industry = action.payload.industry;
      state.size = action.payload.size;
      state.url = action.payload.url;
      state.hqLocation = action.payload.hqLocation;
      state.description = action.payload.description;
      state.logo = action.payload.logo;
      state.cover_image_url = action.payload.cover_image_url;
      state.CompanyName = action.payload.CompanyName;
    },
    ClearCompanyData: (state) => {
      state.company_id = "";
      state.name = "";
      state.industry = "";
      state.size = "";
      state.url = "";
      state.hqLocation = {id:"", city:"", state:"", country:""};
      state.description = "";
      state.logo = "";
      state.cover_image_url = "";
      state.CompanyName = "";
    },
  },
});

export const { setCompanyData, ClearCompanyData, setCompanyDataError } =
  companyDataSlice.actions;
export default companyDataSlice.reducer;
