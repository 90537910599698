import { createSlice } from '@reduxjs/toolkit';


const initialState = {
 
  is_add_company: false
};

const AddCompanySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    setIsAddCompany: (state, action) => {
      state.is_add_company= action.payload.is_add_company
    },
    clearIsAddCompany: (state) => {
    
      state.is_add_company=false
    },
 
  },
});

export const { setIsAddCompany,  clearIsAddCompany,  } = AddCompanySlice.actions;

// Thunk action to log in the user






export default AddCompanySlice.reducer;
