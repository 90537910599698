import RenderRoutes from './routes/RenderRoutes'
import {BrowserRouter} from 'react-router-dom'


function App() {
 


  return (
      <BrowserRouter>
        <RenderRoutes />
      </BrowserRouter>      
  )
}

export default App
